import React from 'react'
import { graphql } from 'gatsby'

import { PageLayout } from '../components/layout/pageLayout/pageLayout'
import { VerticalLayout } from '../components/layout/verticalLayout/verticalLayout'
import { Header } from '../components/header/header'
import { PagePreview } from '../components/pagePreview/pagePreview'

export default ({ data }) => {
  const posts = data.allMarkdownRemark.edges

  return (
    <PageLayout
      title="Articles | eliamainnnnnn"
      description="A blog about software and other stuff, by Elia Maino. All the articles in one page."
    >
      <Header isHome={false} needsHeading={true} />
      <VerticalLayout>
        {posts.map(({ node }) => {
          const link = node.fields.slug
          const { title, description, date } = node.frontmatter

          return (
            <PagePreview
              key={link}
              title={title}
              details={[date]}
              previewText={description}
              link={link}
            />
          )
        })}
      </VerticalLayout>
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
