import React from 'react'
import styles from './header.module.scss'

import { About } from '../about/about'
import { HomeHeadingLink } from '../homeHeadingLink/homeHeadingLink'
import { VerticalLayout } from '../layout/verticalLayout/verticalLayout'

export const Header = ({ isHome }) => (
  <header className={styles.header}>
    <VerticalLayout>
      <HomeHeadingLink isHome={isHome} />
      <About />
    </VerticalLayout>
  </header>
)
