import React from 'react'
import styles from './pagePreview.module.scss'
import { Link } from 'gatsby'
import { PagePreviewDetails } from '../pagePreviewDetails/pagePreviewDetails'

export const PagePreview = ({ title, details, previewText, link }) => (
  <div className={styles.previewContainer}>
    <Link to={link}>
      <h2 className={styles.previewHeading}>{title}</h2>
    </Link>
    <PagePreviewDetails details={details} />
    <p className={styles.previewText}>{previewText}</p>
  </div>
)
